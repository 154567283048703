import { useGrid } from "../../shared/composables/useGrid";

const MAX_REGISTRY_HOURS = 14;
const MIN_REGISTRY_SECONDS = 30;

export const useRegistriesGrid = (renderTable) => {
    const { defaultColDef, rowData, onGridReady, exportAsCSV } = useGrid(renderTable);

    const isInValidRegistry = (params) => {
      return !isValidRegistry(params);
    };

    const isValidRegistry = (params) => {
      if (params.data.time_spend < MIN_REGISTRY_SECONDS) {
        return false;
      }
      
      return params.data.time_spend < MAX_REGISTRY_HOURS * 60 * 60;
    };
    const timeSpendRenderer = (timeSpend) => {
      if (!isValidRegistry(timeSpend)) return "INVALID";

      if (timeSpend.value)
        return new Date(timeSpend.value * 1000).toISOString().substr(11, 8);
      else return "-";
    };

    return {
        defaultColDef, 
        rowData, 
        onGridReady, 
        exportAsCSV,

        isInValidRegistry,
        isValidRegistry,
        timeSpendRenderer,

        rowClassRules: {
            "row-danger": isInValidRegistry,
        },

        statusColumnDef: { 
          headerName: "Status", 
          field: "end_time", 
          filter: 'selectFilter',
          filterParams: {
              options: [{value: 'Open', text: 'Open'}, {value: 'Closed', text: 'Closed'}, {text: 'No filter'}],
              columnName: 'end_time',
              filterCondition: (cellValue, selectedValue) => {
                  if (!selectedValue) return true;
                  return selectedValue == 'Closed' ? cellValue :!cellValue;
              }
          },
          cellRenderer: (params) => {
              console.log(params.value);
              return (params.value) ? 'Closed' : 'Open'
          } 
        }
    }
}