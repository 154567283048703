<template>
<div class="modal fade" :id="idModal" :ref="idModal" tabindex="-1" aria-labelledby="modal-product" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ title }}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <form>
                <div class="mb-3">
                    <label for="input-user" class="form-label">User</label>
                    <SelectInput
                            :options="users" 
                            :reduceFun="item => item.code"
                            :invalidFeedbackText="valErrors.user"
                            v-model="user" />
                </div>
                <div class="mb-3">
                    <label for="input-reference" class="form-label">Production order</label>
                    <SelectInput
                            :options="orders" 
                            :reduceFun="item => item.code"
                            :invalidFeedbackText="valErrors.production_order"
                            v-model="production_order" />
                </div>
                <div class="mb-3">
                    <label for="input-start-time" class="form-label">Start time</label>
                    <Datepicker 
                        uid="start-time"
                        inputClassName="form-control"
                        v-model="start_time" aria-describedby="starttimeHelp" />
                    <div v-if="valErrors.start_time === ''" id="starttimeHelp" class="form-text">Set work initial time and date.</div>
                    <div v-else class="form-text text-danger">{{ valErrors.start_time }}</div>
                </div>
                <div class="mb-3">
                    <label for="input-reference" class="form-label">End time</label>
                    <Datepicker 
                        uid="end-time"
                        inputClassName="form-control"
                        v-model="end_time" aria-describedby="endtimeHelp" />
                    <div v-if="valErrors.end_time === ''" id="endtimeHelp" class="form-text">Set work end time and date.</div>
                    <div v-else class="form-text text-danger">{{ valErrors.end_time }}</div>
                </div>

            </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" @click="submit">{{ submitButtonText }}</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css';

import { ref, reactive, watch, onMounted } from 'vue';

import { getUsers, getOrders } from '../helpers/endpoints';
import SelectInput from '../../shared/components/SelectInput';

export default {
    components: {
        Datepicker,
        SelectInput
    },
    props: {
        title: {
            required: true,
            type: String
        },
        idModal: {
            required: true,
            type: String
        },
        submitButtonText: {
            required: true,
            type: String
        },
        formData: {
            type: Object
        },
        validations: {
            type: Object
        }
    },
    
    emits: ['submit'],

    setup(props, context) {
        const user = ref(null)
        const production_order = ref('')
        const start_time = ref(null)
        const end_time = ref(null)

        const users = ref([])
        const orders = ref([])

        const valErrors = reactive({user: '', production_order: '', start_time: '', end_time: ''})

        
        const initComponent = async() => {
            let usersGet = await getUsers()
            usersGet.forEach(element => {
                users.value.push({ label: element.username, code: element.username })
            })

            let ordersGet = await getOrders()
            ordersGet.forEach(element => {
                orders.value.push({ label: element.code, code: element.code })
            })
        }

        const submit = ($event) => {
            cleanValidations()
            const formData = { 
                user: user.value,
                production_order: production_order.value,
                start_time: start_time.value,
                end_time: end_time.value
            }
            console.log(formData);
            context.emit('submit', $event, formData)
        }

        const cleanForm = () => {
            cleanValidations()
            user.value = null
            production_order.value = ''
            start_time.value = null
            end_time.value = null
        }

        const cleanValidations = () => {
            for (const field in valErrors) {
                valErrors[field] = ''
            }
        }

        watch(() => props.formData, (newFormData) => {
            console.log(newFormData);
            if (newFormData) {
                user.value = newFormData.user
                production_order.value = newFormData.production_order
                start_time.value = newFormData.start_time
                end_time.value = newFormData.end_time
            }
        })

        watch(() => props.validations, (newValidations) => {
            cleanValidations()
            for (const field in newValidations) {
                console.log(`Error in field ${field}: ${newValidations[field]}`);
                valErrors[field] = newValidations[field][0]
            }
        })

        watch(() => start_time, (newValue) => {
            console.log(newValue);
            let start_date = new Date(newValue).setHours(0,0,0,0)
            console.log(start_date);
            if (!end_time.value) {
                end_time.value = newValue
            }
        })

        watch(() => valErrors.start_time, (newValue) => {
            console.log(newValue);
            let input = document.getElementById('dp-input-start-time')
            if (newValue !== '') {
                input.classList.remove("is-valid");
                input.classList.add('is-invalid')
            } else {
                input.classList.add('is-valid')
                input.classList.remove("is-invalid");
            }
        })

        watch(() => valErrors.end_time, (newValue) => {
            console.log(newValue);
            let input = document.getElementById('dp-input-end-time')
            if (newValue !== '') {
                input.classList.remove("is-valid");
                input.classList.add('is-invalid')
            } else {
                input.classList.add('is-valid')
                input.classList.remove("is-invalid");
            }
        })

        initComponent()

        onMounted(() => {
            let $modal = document.getElementById(props.idModal)
            $modal.addEventListener('hidden.bs.modal', function () {
                cleanForm()
            })
        })


        return {
            user,
            production_order,
            start_time,
            end_time,

            users,
            orders,

            valErrors,

            submit,
            cleanForm
        }
    }

}
</script>

<style>

</style>