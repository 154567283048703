<template>

<div class="grid-page-wrapper">
    <h1 class="display-4">Registers</h1> 
    <hr>

    <ModalFormRegistry
        ref="formCreate"
        idModal="modal-registry-create" 
        title="New register" 
        submitButtonText="Add register"
        :validations="formValidations"
        @submit="createItem"/>

    <ModalFormRegistry
        ref="formEdit"
        idModal="modal-registry-edit" 
        :title="'Edit register: ' + editData.reference" 
        submitButtonText="Edit register"
        :formData="editData"
        :validations="formValidations"
        @submit="editItem"/>

    <div class="row mb-2">
        <div class="col-12">
            <button class="btn btn-success"
                    data-bs-toggle="modal" 
                    data-bs-target="#modal-registry-create"
             ><i class="fa-solid fa-plus"></i> Add</button>
            <button class="btn btn-secondary dropdown-toggle mx-2"                     
                    id="btn-dropdown-export-table"
                    data-bs-toggle="dropdown">
                Export
            </button>
            <ul class="dropdown-menu" aria-labelledby="btn-dropdown-export-table">
                <li><button class="dropdown-item" @click="onBtExport()"><i class="fa-solid fa-file-csv"></i> CSV</button></li>
            </ul>
            <button v-if="showAll" @click="swapShowAll" class="btn btn-dark float-end">
                <i class="fa-solid fa-eye-slash"></i> Hide finished work orders
            </button>
            <button v-else @click="swapShowAll" class="btn btn-dark float-end">
                <i class="fa-solid fa-eye"></i> Show finished work orders
            </button>
        </div>
    </div>
    <div class="grid-wrapper">
        <ag-grid-vue
            class="backoffice-grid ag-theme-alpine"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            @grid-ready="onGridReady"
            :rowClassRules="rowClassRules"
            :frameworkComponents="frameworkComponents"
            :paginationAutoPageSize="true"
            :pagination="true"
            :rowData="rowData">
        </ag-grid-vue>
    </div>
</div>


</template>


<script>
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"

import { ref } from 'vue'
import { AgGridVue } from "ag-grid-vue3"
import { Modal } from 'bootstrap'
import Swal from 'sweetalert2'

import TableButtonEdit from "../../shared/components/TableButtonEdit"
import TableButtonDelete from "../../shared/components/TableButtonDelete"
import GridColumnSelectFilter from "../../shared/components/GridColumnSelectFilter"
import { dateComparator } from '../../shared/helpers/GridComparators'
import { timeRenderer, dateRenderer } from '../../shared/helpers/GridRenderers'
import { defaultButtonColumnDef } from '../../shared/helpers/GridHelpers'

import ModalFormRegistry from '../components/ModalFormRegistry'
import { getRegistries, insertRegistry, updateRegistry, deleteRegistry } from '../helpers/endpoints';
import { useRegistriesGrid } from "../composables/useRegistriesGrid";


export default {
    components: {
        AgGridVue,
        ModalFormRegistry
    },
    setup() {
        const showAll = ref(false);

        const {
            statusColumnDef,
            rowData,
            onGridReady,
            exportAsCSV,
            timeSpendRenderer,
            rowClassRules
        } = useRegistriesGrid(() => {
            getRegistries({ get_all: showAll.value}).then((response) => {
                rowData.value = response;
            })
        });

        const editData = ref({});
        const formValidations = ref({});

        const renderTable = () => {
            getRegistries({ get_all: showAll.value}).then((response) => {
                rowData.value = response;
            })
        };

        const openEditModal = (formData) => {
            console.log(formData);
            editData.value = formData

            let modalEdit = new Modal(document.getElementById('modal-registry-edit'))
            modalEdit.show()
        }

        const createItem = ($event, formData) => {
            console.log("Creating registry");
            console.log(formData);
            insertRegistry(formData).then((response) => {
                console.log(response);
                renderTable()

                let modalCreate = Modal.getInstance(document.getElementById('modal-registry-create'))
                modalCreate.hide()
                
                Swal.fire(
                    'Good job!',
                    'Registry added with success',
                    'success'
                )
                
            }).catch((error) => {
                const { data } = error.response
                formValidations.value = data
            })
        }

        const editItem = ($event, formData) => {
            console.log("Editing registry");
            console.log($event);
            console.log(formData);
            updateRegistry(editData.value.id, formData).then((response) => {
                console.log(response);
                renderTable()

                let modalEdit = Modal.getInstance(document.getElementById('modal-registry-edit'))
                modalEdit.hide()
                
                Swal.fire(
                    'Good job!',
                    'Registry edited with success',
                    'success'
                )
                
            }).catch((error) => {
                const { data } = error.response
                formValidations.value = data
            })

        }

        const removeItem = ({ id }) => {
            console.log(`Deleting registry ${id}`);
            console.log(id);
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {

                    deleteRegistry(id)
                        .then((response)=> {
                            console.log(response)
                            renderTable()
                            swalWithBootstrapButtons.fire(
                                'Deleted!',
                                'Registry has been deleted.',
                                'success'
                            )
                        })
                    
                } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    swalWithBootstrapButtons.fire(
                        'Cancelled',
                        'Your registry is safe :)',
                        'error'
                    )
                }
            })
        }

        const swapShowAll = () => {
            showAll.value = !showAll.value;
            renderTable();
        }

        return {
            columnDefs: [
                { headerName: "User", field: "user", filter: 'agTextColumnFilter' },
                { headerName: "Order", field: "production_order", filter: 'agTextColumnFilter' },
                { 
                    headerName: "Date", 
                    field: "start_time",
                    filter: 'agDateColumnFilter',
                    cellRenderer: dateRenderer,
                    // add extra parameters for the date filter
                    filterParams: {
                        // provide comparator function
                        comparator: dateComparator
                    } 
                },
                { 
                    headerName: "Start time", 
                    field: "start_time", 
                    cellRenderer: timeRenderer 
                },
                { 
                    headerName: "End time", 
                    field: "end_time", 
                    cellRenderer: timeRenderer 
                },
                { 
                    headerName: "Registry time (HH:mm:ss)", 
                    field: "time_spend", 
                    cellRenderer: timeSpendRenderer 
                },
                statusColumnDef,
                {
                    field: 'id',
                    cellRenderer: 'btnEditRenderer',
                    cellRendererParams: {
                        action: openEditModal
                    },
                    ...defaultButtonColumnDef
                },
                {
                    field: 'id',
                    cellRenderer: 'btnDeleteRenderer',
                    cellRendererParams: {
                        action: removeItem
                    },
                    ...defaultButtonColumnDef
                }
            ],
            defaultColDef: {
                flex: 1,
                minWidth: 100,
                resizable: true,
                floatingFilter: true,
                sortable: true
            },
            frameworkComponents: {
                btnEditRenderer: TableButtonEdit,
                btnDeleteRenderer: TableButtonDelete,
                selectFilter: GridColumnSelectFilter
            },
            rowClassRules,
            rowData,
            onGridReady,

            editData,
            formValidations,

            showAll,

            createItem,
            editItem,

            onBtnExport: () => exportAsCSV(),
            swapShowAll,
        }
    }
}
</script>
<style lang="scss">
.row-danger {  
    transition: background-color 0.1s !important;
    background-color: rgb(248, 207, 207) !important;
}
</style>