<template>
    <div style="width: 200px;" class="m-2">
        <select id="input-status" 
                v-model="selection" 
                class="form-select" 
                @change="updateFilter()"
                aria-label="Select value">
            <option v-for="opt in options" :key="opt.value" 
                    :value="opt.value">
                    {{ opt.text }}
            </option>
        </select>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue'

export default {

    setup(props) {
        console.log(props);
        const selection = ref('');

        const columnName = ref();
        const options = ref();

        onMounted(() => {
            columnName.value = props.params.columnName;
            options.value = props.params.options;
        })
        
        return {
            selection,
            options,
            
            updateFilter() {
                props.params.filterChangedCallback();
            },

            doesFilterPass(params) {
                if (props.params.filterCondition) {
                    return props.params.filterCondition(params.data[columnName.value], 
                        selection.value)
                } else {
                    return params.data[columnName.value] == selection.value;
                }

            },

            isFilterActive() {
                return true;
            },

            getModel() {
                return selection.value;
            },

            setModel(model) {
                selection.value = model;
            },

            getModelAsString: (model) => {
                return model;
            }
        }
    }
}
</script>

<style>

</style>